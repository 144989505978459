export default {
favorites_link: "OBLÍBENÉ",
your_recomendations: "Vaše doporučení",
loading_perfumes: "Načítání parfému",
filter_by_gender: "Pohlaví",
for_him: "Pro něj",
for_her: "Pro ni",
filter_by_family: "Rodina",
filter_by_brand: "Označit",
all: "Všechno",
all_femenine: "Všechno",
you_may_also_like: "Mohlo by s vám líbit",
ingredients: "přísady",
perfumist: "parfumér",
description: "popis",
olfactive_family: "rodina vůní",
expert_reviewed: "kontrolovány odborníky",
main_family: "rodina",
sub_family: "podrodina",
me_family: "Vůně světa",
promo_code_1: "Získejte 5% slevu s exkluzivním promo kódem <b> AIRPARFUM5 </b>.",
promo_code_2: "Akce platí měsíc od data vaší návštěvy.",
tag_new: "Nové",
from: "od"
}