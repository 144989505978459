export default {
favorites_link: "FAVOURITES",
your_recomendations: "Your recommendations",
loading_perfumes: "Loading perfume",
filter_by_gender: "Gender",
for_him: "For Him",
for_her: "For Her",
filter_by_family: "Family",
filter_by_brand: "Brand",
all: "All",
all_femenine: "All",
you_may_also_like: "You may also like",
ingredients: "Ingredients",
perfumist: "Perfumist",
description: "Description",
olfactive_family: "Olfactive family",
expert_reviewed: "Expert reviewed",
main_family: "Family",
sub_family: "Subfamily",
me_family: "Fragrances of the World",
promo_code_1: "Get 5% discount with an exclusive promo code <b> AIRPARFUM5 </b>.",
promo_code_2: "The promotion is valid for one month from the date of your visit.",
tag_new: "Novelty",
from: "from"
}