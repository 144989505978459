import axios from "axios";
import store from "@/store";
import staticDataApi from "./StaticData";
import CacheApi from "./Cache";
import catalogueAlgorithm from "@bestiario/catalogue-algorithms";

// Axios instance
const instance = axios.create();
/** custom color log function */
// function log(...args) {
//   console.debug("%c [API]", "color:#82CAFA", ...args);
// }
/** Get Host id from VUEX Store */
const getHostId = () => {
  const hostId = store.getters.hostId;
  console.debug("---- Host ID =", hostId, "----");
  return Number(hostId);
};
/** Get Lang from Vuex store */
const getLang = () => {
  return store.getters.lang;
};

export function getAllStaticMixes() {
  return Promise.all([
    getStaticMixes("FU"),
    getStaticMixes("MU"),
    getStaticMixes("U"),
  ]).then((resps) => {
    return {
      FU: resps[0],
      MU: resps[1],
      U: resps[2],
    };
  });
}

/**
 *
 * @param {string} gender
 * @returns {ean:string, mixes:{{eans:[number,number], percentages:[number,number]}[]}}
 */
export async function getStaticMixes(gender, lang = getLang()) {
  return cacheWrapper.wrap(`getStaticMixes/${gender}/${lang}`, async () => {
    const rows = await staticDataApi.getCombinations();
    const mainCartridges = await staticDataApi.getMainCartridges();
    const perfumes = await getPerfumesByParams({ gender }, lang);
    const cartridges = await getPopulatedCartridges(gender, lang);
    const cartridgeIngredient = await staticDataApi.getIngredients();

    let res = rows.reduce((ob, row) => {
      // if (!row[ColumnsNotinoMixes.familyEan]) return ob;
      const mainCartridge = cartridges.find((c) => c.id === row.perfume_id1);
      const mainCartridgeMeta = mainCartridges.find(
        (c) => c.perfume_id === row.perfume_id1
      );
      const subCartridge = cartridges.find((c) => c.id === row.perfume_id2);
      // Validate if exist these cartridges
      if (!mainCartridge || !subCartridge) return ob;
      // Right format for somePerfumeInCartridgeCombination
      const cartridgeType = (mainCartridge.id === subCartridge.id
        ? [mainCartridge]
        : [mainCartridge, subCartridge]
      ).map((e) => ({ ...e, family: e.family.id }));
      // Validate is this combo has some perfume
      const somePerfume = catalogueAlgorithm.somePerfumeInCartridgeCombination(
        perfumes,
        cartridgeType,
        cartridgeIngredient
      );
      if (!somePerfume) return ob;

      // Format response
      // family object
      let fam = ob[mainCartridge.ean];
      if (!fam)
        fam = {
          ean: mainCartridge.ean,
          mixes: [],
          power: mainCartridgeMeta.power,
        };
      ob[mainCartridge.ean] = fam;
      let percs = [+row.mix_share_perfume_id1, +row.mix_share_perfume_id2];
      if (percs[0] || percs[1]) {
        fam.mixes.push({
          eans: [mainCartridge.ean, subCartridge.ean],
          percentages: percs,
          cartridgeObjects: [mainCartridge, subCartridge],
          power: row.power,
        });
      }
      return ob;
    }, {});
    const data = Object.keys(res).map((key) => {
      return res[key];
    });
    return data;
  });
}

export async function getRecommendedPerfumes(params) {
  const perfumes = await getPerfumesByParams(params);
  const ingredientCartridges = await staticDataApi.getIngredients();

  const data = catalogueAlgorithm.getRecommendationByCartridges(
    perfumes,
    ingredientCartridges,
    params.cartridgePair.map((cartridges) =>
      cartridges.map((cartridge) => ({
        ...cartridge,
        family: cartridge.family.id,
      }))
    ),
    [],
    params.gender,
    null, // No alternate (or filter) by budgets
    params.seed // random seed
  );
  return data;
}
/** Cache object for wrap request  */
var cacheWrapper = new CacheApi();
export async function getPerfumes(lang = getLang()) {
  return cacheWrapper.wrap(`get-perfumes/${lang}`, async () => {
    //file/catalog?hostId=%hostId%&lang=%langs%&configId=%configId%`
    return instance
      .get(
        process.env.VUE_APP_API_URL +
          "/file/catalog/?hostId=" +
          getHostId() +
          `&lang=${lang}`,
        {
          headers: {
            apikey: process.env.VUE_APP_APIKEY,
            safetyCode: process.env.VUE_APP_SAFETY_CODE,
          },
        }
      )
      .then((response) => response.data);
  });
}
export async function getPerfumesByParams({ gender }, lang = getLang()) {
  return cacheWrapper.wrap(
    `get-perfumes-by-params/${gender}/${lang}`,
    async () => {
      const perfumes = await getPerfumes(lang);
      if (!gender) return perfumes;
      const genderArray = [gender]
        .flat()
        .map((g) => g.split(""))
        .flat();
      return perfumes.filter((perfume) =>
        genderArray.some((genderStr) => perfume.gender === genderStr)
      );
    }
  );
}
/** @return {Map<number,Bestiario.Perfume["family"]>} Grouped Perfumes by families */
async function getMapPerfumes(gender, lang = getLang()) {
  return cacheWrapper.wrap(`getMapPerfumes/${gender}/${lang}`, async () => {
    const perfumes = await getPerfumesByParams({ gender }, lang);
    const mapPerfumes = new Map();
    for (let p of perfumes) {
      mapPerfumes.set(p.id, p);
    }
    return mapPerfumes;
  });
}

async function getFamilyMap(gender) {
  return cacheWrapper.wrap(`getMapPerfumes/${gender}`, async () => {
    const perfumes = await getPerfumesByParams({ gender });
    const familyMap = new Map();
    for (let p of perfumes) {
      familyMap.set(String(p.family.id), p.family);
    }
    return familyMap;
  });
}

export async function getPerfume(id, lang = getLang()) {
  const mapPerfumes = await getMapPerfumes(null, lang);
  return mapPerfumes.get(Number(id));
}

const APP_NAME = process.env.VUE_APP_ID;
// const RETAILER_ID = Number(process.env.VUE_APP_RETAILER_ID);

/** @typedef {import('./host').Host} Host */
/**
 *
 * @returns {Promise<Host['sensors']>}
 */
export async function getInstallations(appName = APP_NAME) {
  return instance
    .get(process.env.VUE_APP_API_URL + "/retailer/secure/hierarchy", {
      headers: {
        apikey: process.env.VUE_APP_APIKEY,
        safetyCode: process.env.VUE_APP_SAFETY_CODE,
      },
    })
    .then((res) => {
      const retailers = res.data;

      /** @type {Host[]} */
      const hosts = retailers
        // .filter((retailer) => retailer.id === RETAILER_ID)
        .reduce((array, retailer) => array.concat(retailer.hosts), []);
      const sensors = hosts
        .reduce(
          (array, host) =>
            array.concat(
              host.sensors?.map((s) => ({ ...s, hostId: host.id } ?? []))
            ),
          []
        )
        .filter((sensor) => sensor?.appName === appName);
      console.debug("[Sensors]", `[${appName}]`, sensors);
      return sensors ?? [];
    });
  //return HARDCODE_INSTALATIONS
}

export async function getInstallationByName(installationName) {
  const installations = await getInstallations(APP_NAME);
  return installations.find((i) => i.installationName === installationName);
}

export async function getQRInstallationByName(installationName) {
  // qr sensors
  const qrSensors = await getInstallations(process.env.VUE_APP_NAME_QR);
  // Check the QR installation
  return qrSensors.find(
    (sensorQR) => sensorQR.installationName === installationName
  );
}

export async function getPopulatedCartridges(gender, lang = getLang()) {
  return staticDataApi.wrap(
    `getPopulatedCartridges/${lang}/${gender}`,
    async () => {
      // Cartridge with cartrige_type set
      const cartridges = await staticDataApi.getCartridges();
      // const cartridgesData = await getPerfumeByIds(cartridges.map((c) => c.id));
      const familyMap = await getFamilyMap(gender);
      const translates = await staticDataApi.getTranslation();

      const data = cartridges
        .map((cartridge) => {
          var family = familyMap.get(String(cartridge.family));
          // Copy of family Object
          family = family ? { ...family } : null;
          cartridge.imageUrl = cartridge.url;

          return {
            name: translates.get(String(cartridge.id))
              ? translates.get(String(cartridge.id))[lang]
              : "NONE",
            ...cartridge,
            family,
          };
        })
        .filter((e) => e.family);

      return staticDataApi.sortCartridges(data);
    }
  );
}
