import { select } from "d3-selection";
import { interpolate } from "d3-interpolate";
import { easeBackOut, easeBackIn, easeCubicInOut } from "d3-ease";
import { geoMercator, geoPath } from "d3-geo";
import { pie, arc } from "d3-shape";
import "d3-transition";
import { format, formatLocale, formatDefaultLocale } from "d3-format";
export default {
  select: select,
  interpolate: interpolate,
  easeBackIn: easeBackIn,
  easeBackOut: easeBackOut,
  easeCubicInOut: easeCubicInOut,
  pie: pie,
  arc: arc,
  geoMercator,
  geoPath,
  format,
  formatLocale,
  formatDefaultLocale,
};
