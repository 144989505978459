import Vue from 'vue'
import VueRouter from 'vue-router'
import Recos from '../views/Recos.vue'
import Perfume from '../views/Perfume.vue'
import store from '../store'
import { getInstallationByName } from "@api-client"
import { eventTracker } from '../services/eventTracker'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Recos',
    component: Recos
  },
  {
    path: '/perfume/:id',
    name: 'Perfume',
    component: Perfume
  },
]

const router = new VueRouter({
  routes
})
router.beforeEach(async (to,from,next)=>{
  var reload = false
  if(to.query.uuid)
    eventTracker.uuid = to.query.uuid

  if(to.query.lang){
    localStorage.setItem('ap_lang',to.query.lang)
    console.debug('set Language')
    store.commit('setLanguage',to.query.lang)
    // reload = true
    // delete to.query.lang
  }
  if (to.query.host_id) {
    localStorage.setItem("ap_host_id", to.query.host_id);
    console.debug("set host_id");
    // reload = true
    // delete to.query.lang
  }
  
  if (to.query.installation_name) {
    localStorage.setItem('installation_name',to.query.installation_name)
    console.debug('set installation_name id',to.query.installation_name)
    const installation_name= to.query.installation_name
    const installation = await getInstallationByName(installation_name === "default" ? undefined : installation_name)
    if(installation)
      eventTracker.installationId = installation.installationName
    console.debug('installation = ',installation)
    store.commit("setInstallation",installation)
    // reload = true
    // delete to.query.host_id
  }
  if(reload)
    next(to)
  next()
  
})


export default router
