import cs from './lang/cs.js'
import en from './lang/en.js'
import pl from './lang/pl.js'
import hu from './lang/hu.js'
import sk from './lang/sk.js'
import store from '@/store'
let storeInstance = store
let allStrings = {
  en:en,
  cs:cs,
  pl:pl,
  hu,
  sk
 };

export function t (key){
  return allStrings[storeInstance.state.i18n.lang][key]|| '['+key+']'
}
export default {
  methods: {
    t
  }
}