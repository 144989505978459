import device from '@/utils/device'
console.log(device)
export default {
  computed: {
    isMobile (){
      return device==='mobile'
    },
    isTablet(){
      return device==='tablet'
    }
  }
}