export default {
favorites_link: "FAVORITES",
your_recomendations: "Twoje rekomendacje",
loading_perfumes: "Wczytywanie perfum",
filter_by_gender: "Płeć",
for_him: "Dla Niego",
for_her: "Dla Niej",
filter_by_family: "Rodzina",
filter_by_brand: "Marka",
all: "Wszystkie",
all_femenine: "Wszystkie",
you_may_also_like: "Może Ci się spodobać",
ingredients: "składniki",
perfumist: "perfumiarz",
description: "opis",
olfactive_family: "rodzina zapachowa",
expert_reviewed: "sprawdzone przez ekspertów",
main_family: "rodzina",
sub_family: "podrodzina",
me_family: "Fragrances of the World",
promo_code_1: "Zyskaj 5% zniżki z ekskluzywnym kodem promocyjnym <b>AIRPARFUM5</b>.",
promo_code_2: " Promocja jest ważna miesiąc od daty Twojej wizyty.",
tag_new: "Nowość",
from: "od"
}