export default {
favorites_link: "KEDVENCEK",
your_recomendations: "Ön ajánlja",
loading_perfumes: "A parfüm töltődik",
filter_by_gender: "Nem",
for_him: "Férfiaknak",
for_her: "Nőknek",
filter_by_family: "Család",
filter_by_brand: "Márka",
all: "Összes",
all_femenine: "Összes",
you_may_also_like: "Ajánljuk még",
ingredients: "Összetevők",
perfumist: "Parfümőr",
description: "Leírás",
olfactive_family: "Illatcsalád",
expert_reviewed: "Szakértők által ellenőrzött",
main_family: "Család",
sub_family: "Alcsalád",
me_family: "A világ illatai",
promo_code_1: "Legyen az Öné 5% kedvezmény egy exkluzív kóddal: <b> AIRPARFUM5 </b>.",
promo_code_2: "Az ajánlat a látogatásától számított 1 hónapig érvényes.",
tag_new: "Új",
from: "tól"
}