import d3 from "./d3Importer";
import locales from "../settings/formats.json";

export function getEanData(perfume) {
  if (!perfume.eanData) return {};
  if (perfume.eanData.length === 0) return {};
  let bestEan = Object.values(perfume.eanData).find((d) => d.isMaster);
  if (!bestEan) bestEan = Object.values(perfume.eanData)[0];
  return {
    sku: bestEan ? bestEan.sku : null,
    notinoPerfumeId: bestEan ? bestEan.notinoPerfumeId : null,
  };
}
export function lowerPrice(perfume) {
  if (!perfume || !perfume.eanData) return null;
  return Object.values(perfume.eanData).sort(lowerPriceEan)?.[0]?.price;
}

function lowerPriceEan(a, b) {
  return a.price > b.price ? 1 : -1;
}

export function formatPrice(price, country) {
  const formatLocale = d3.formatLocale(locales[country]);
  return formatLocale.format(locales[country]["format"])(price);

  // return formatLocale.format('$,.2f')(price)
}
/**
 *
 * @param {Bestiario.Perfume} data Perfume data
 * @param {string} field field of ean data
 * @returns
 */
export function getFieldFromEANs(data, field) {
  return Object.values(data?.eanData)
    .sort(lowerPriceEan)
    .find((data) => data?.[field])?.[field];
}
/**
 *
 * @param {Bestiario.Perfume} perfume
 * @param {string} country
 * @returns
 */
export function formatLowerPrice(perfume, country) {
  const price = lowerPrice(perfume);
  if (!price) return null;
  if(!country) return null
  return formatPrice(price, country);
}
/**
 *
 * @param {Bestiario.Perfume} data
 * @returns {Boolean}
 */
export function isNew(data) {
  // For testing uncomment next line
  // return Math.random() > 0.5;
  return Object.values(data.eanData).some(({ isNew }) => isNew);
}
