export default {
  state: {
    lang: localStorage.getItem('ap_lang') || 'en'
  },
  mutations: {
    setLanguage (state, lang) {
      state.lang = lang
    }
  },
  actions: {
    setLanguage (context, lang) {
      console.log('set lang')
      context.commit('setLanguage', lang)
      context.dispatch('loadStaticData')
    }
  }
}
