<template>
  <div class="family-donut">
    <div
      class="outer"
      :style="{backgroundColor: family.color, backgroundImage: `url(${family.imageUrl})`}"
    ></div>
    <div class="inner" :style="{background: subfamily.color}"></div>
  </div>
</template>

<script>
export default {
  name: 'FamilyDonut',
  components:{
  },
  props: ['family','subfamily'],
  methods:{
  }
}
</script>

<style scoped lang="stylus">
$radius = vh(100px)
$image-size = vh(200px)

.family-donut
  height: $radius
  margin-right: vw(10px)
  position: relative
  width: @height

  .outer,
  .inner
    left: 50%
    position: absolute
    top: 50%
    transform: translate(-50%, -50%)

  .outer
    background-position: center
    background-repeat: no-repeat
    background-size: $image-size !important
    border-radius: 50%
    height: 100%
    width: @height

  .inner
    border: 1px solid rgba(#fff, 0.5)
    border-radius: 50%
    height: 50%
    opacity: 0.75
    width: @height
</style>