<template>
  <div
    itemscope
    itemtype="http://schema.org/Product"
    class="perfume-card"
    @click="$emit('click', data)"
  >
    <FamiliesWithIcon
      itemprop="additionalProperty"
      :family="data.family"
      :subfamily="data.secondaryFamily"
    ></FamiliesWithIcon>
    <div class="image" :style="{ backgroundImage: `url(${data.imageUrl})` }">
      <div class="tags">
        <div v-if="isNew" class="tag new">{{ t("tag_new") }}</div>
      </div>
    </div>
    <div itemprop="brand" class="brand">{{ data.brand.name }}</div>
    <div itemprop="name" class="name">{{ data.name }}</div>
    <div v-if="price" itemprop="price" class="price">{{t("from")}} {{ price }}</div>
    <div
      class="remove-button"
      v-if="showRemove"
      @click.stop="$emit('remove', data)"
    ></div>
  </div>
</template>

<script>
import FamiliesWithIcon from "@/components/wikiparfum/FamiliesWithIcon";
import { formatLowerPrice, isNew } from "../../utils/perfume";
import { mapGetters } from "vuex";

export default {
  name: "PerfumeCard",
  components: {
    FamiliesWithIcon,
  },
  props: ["data", "showRemove"],
  computed: {
    ...mapGetters(["countryCode"]),
    isNew() {
      return isNew(this.data);
    },
    price() {
      return formatLowerPrice(this.data, this.countryCode);
    },
  },
  methods: {},
};
</script>


<style scoped lang="stylus">
.perfume-card
  align-items: center
  background-color: #fff
  border: 1px solid rgba(#000, 0.15)
  cursor: pointer
  display: flex
  flex-direction: column
  overflow: hidden
  padding: vw(10px)
  position: relative

  .desktop &
    padding: vw(5px)

  .image
    background-position: center center
    background-repeat: no-repeat
    background-size: contain
    flex: 1 1 0%
    margin-bottom: vw(20px)
    margin-top: vw(20px)
    position: relative
    width: 100%

    .desktop &
      margin-bottom: vh(10px)
      margin-top: vh(10px)

    .tags
      bottom: 0
      left: 50%
      position: absolute
      transform: translate(-50%, 50%)

      .tag
        m-font-size(10, 10)

        .desktop &
          m-font-size(8, 8)
          padding: vw(3px) vw(8px)

  .name
    m-ellipsis(100%)
    m-font-size(10, 14)
    font-weight: 600

  .price
    m-font-size(10, 14)
    font-weight: 600
    margin-top: vw(10px)

    .desktop &
      margin-top: vw(3px)

  .brand
    m-ellipsis(100%)
    m-font-size(8, 10)
    text-transform: uppercase

    .desktop &
      margin-top: vw(5px)

  .remove-button
    m-icon("close", 15)
    cursor: pointer
    position: absolute
    right: vw(4px)

  .family-and-icon
    width: 100%
</style>