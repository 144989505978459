<template>
  <div
    itemscope
    itemtype="http://schema.org/PropertyValue"
    class="family-and-icon"
  >
    <div class="icon">
      <div class="outer" :style="{ background: family.color }"></div>
      <div
        class="inner"
        :style="{ background: subfamily ? subfamily.color : family.color }"
      ></div>
    </div>
    <meta itemprop="name" content="family" />
    <div itemprop="value" class="label" v-tooltip.top="label">{{ label }}</div>
  </div>
</template>

<script>
import { VTooltip } from "v-tooltip";
export default {
  name: "FamilyWithIcon",
  components: {},
  directives: {
    tooltip: VTooltip,
  },
  props: ["family", "subfamily"],
  methods: {},
  computed: {
    label() {
      if (this.family.name) {
        if (this.subfamily)
          return this.family.name + " / " + this.subfamily.name;
        return this.family.name;
      } else return "";
    },
  },
};
</script>

<style scoped lang="stylus">
.family-and-icon
  align-items: center
  cursor: pointer
  display: flex
  text-align: left

  .icon
    // added 2 extra pixels for deciaml cutting
    height: vw(11px)
    margin-right: vw(5px)
    position: relative
    width: @height

    // padding: vw(9px) //sirve para evitar que corte por "medios pixeles"
    .mobile &
      margin-right: vw(5px)

    .outer,
    .inner
      left: 50%
      position: absolute
      top: 50%
      transform: translate(-50%, -50%)

    .outer
      border-radius: 50%
      height: vw(10px)
      width: @height

    .inner
      border: solid 1px rgba(255, 255, 255, 1)
      border-radius: 50%
      height: vw(5px)
      width: @height

    .mobile &
      // added 2 extra pixel for deciaml cutting
      width: vw(17px)

      .outer
        height: vw(15px)
        width: @height

      .inner
        height: vw(7px)
        width: @height

  .label
    m-font-size(8, 10)
    m-ellipsis(100%)
    align-items: center
    flex: 1 1 0%
    // display: flex
    font-weight: $fw-medium

    // Para ue quede un poco mejor el tooltip...
    // height: 100%
    .mobile &
      m-font-size(11, 14)
</style>