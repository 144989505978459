<template>
  <div class="perfume-view">
    <PerfumeFullView
      v-if="data"
      :data="data"
      @close="onClose"
      :canShowSimilar="false"
      :showClose="true"
      :tabletFormat="isTablet && Boolean(showSimilar)"
      @update="onFullViewUpdated"
    ></PerfumeFullView>
  </div>
</template>

<script>
import PerfumeFullView from '@/components/wikiparfum/PerfumeFullView'
import {getPerfume} from '@/services/api'
import { eventTracker } from '../services/eventTracker'
export default {
  components:{PerfumeFullView},
  mounted() {
    eventTracker.emitEvent('VIEW_OPENED',{
      type:"PERFUME_VIEWED",
      data:{id:this.$route.params.id}
    })
  },
  asyncComputed:{
    data () {
      return getPerfume(this.$route.params.id)
    }
  },
  methods: {
    onFullViewUpdated(){
      
    },
    onClose(){
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="stylus" scoped>
.perfume-view
  overflow: auto;

</style>