import axios from "axios";
import CacheApi from "./Cache";
/** @enum */
const StaticFilesNames = {
  /** specific files to Notino app */
  Cartridges: "notino-airparfum/cartridges.json",
  CartridgesTranslation: "notino-airparfum/cartridge_translations.json",
  /** generic files: Combinations */
  Combinations: "combinations.json",
  FirstLevel: "first_level_options.json",
  Ingredients: "ingredients.json",
};

class StaticData extends CacheApi {
  baseURL = process.env.VUE_APP_S3_STATIC_FILES_GENERIC;
  lastHostId;
  /**
   *
   * @returns {Promise<StaticCartridge[]>}
   */
  async getCartridges() {
    return this.wrap("getCartridges", async () => {
      const promise = axios.get(this.baseURL + StaticFilesNames.Cartridges);
      const res = await promise;
      const data = res.data;
      const mainCartridges = await this.getMainCartridges();
      data.forEach((cartridge) => {
        cartridge.cartridge_type = mainCartridges.some(
          (c) => c.perfume_id === cartridge.id
        )
          ? "base"
          : "accord";
      });
      return data;
    });
  }
  /**
   *
   * @returns { Promise<StaticCartridgeCombination[]>}
   */
  async getCombinations() {
    const promise =
      super.get("cartridges-combinations") ??
      axios.get(this.baseURL + StaticFilesNames.Combinations);
    super.set("cartridges-combinations", promise);
    const res = await promise;
    const data = res.data;
    return data;
  }
  async getTranslation() {
    return this.wrap("translations", async () => {
      const promise = axios.get(
        this.baseURL + StaticFilesNames.CartridgesTranslation
      );
      const res = await promise;
      /** @type {Map<string, Record<string, string>>} */
      const map = new Map();
      res.data.forEach((e) => {
        map.set(String(e.perfume_id), e);
      });
      return map;
    });
  }
  /**
   *
   * @returns {Promise<StaticFirstLevel[]>}
   */
  async getMainCartridges() {
    const promise =
      super.get("main-cartridges") ??
      axios.get(this.baseURL + StaticFilesNames.FirstLevel);
    super.set("main-cartridges", promise);
    const res = await promise;
    const data = res.data;
    return data;
  }
  /**
   *
   * @returns {{ family: number; cartridge: number; ingredient: number }[]}
   */
  async getIngredients() {
    return this.wrap("/ingredients", async () => {
      return axios
        .get(this.baseURL + StaticFilesNames.Ingredients)
        .then((res) => res.data);
    });
  }
  /**
   *
   * @param {*} host
   * @param {*} lang
   * @returns {Promise<Bestiario.Perfume[]>}
   */
  async getPerfumes(host, lang) {
    const path = `/file/catalog?hostId=${host}&lang=${lang}`; //`host_id_${host}.json`;
    return this.wrap(path, async () => {
      return axios
        .get(path, {
          headers: {
            apikey: process.env.VUE_APP_API_KEY,
            safetyCode: process.env.VUE_APP_SAFETY_CODE,
          },
        })
        .then((res) => res.data);
    });
  }
  /**
   *
   * @param {T[]} cartridges
   * @returns { T[] }
   * @template {{family:Bestiario.Perfume['family']}} T
   */
  sortCartridges(cartridges) {
    return cartridges.sort(
      (cartridge1, cartridge2) =>
        cartridge1.family.intensity - cartridge2.family.intensity
    );
  }
}

export default new StaticData();
