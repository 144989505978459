<template>
  <div :class="['button-with-feedback',{down}]" @click="handleClick">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ButtonWithFeedback',
  data () {
    return{
      down: false
    }
  },
  methods:{
    handleClick(){
      this.down = true
      setTimeout(()=>{
        this.down=false
        this.$emit('click')
      },100)
    }
  }
}
</script>

<style scoped lang="stylus">
  .button-with-feedback{
    transition: opacity .1s
    cursor: pointer;
    &.down{
      opacity: 0.7;
    }
  }
</style>