import { uuid } from 'vue-uuid'
// import { track } from '@/services/tracker'
// import * as machine from '@/services/machine/machineInterface.js'

export default {
  state: {
    gender: null,
    activeFamily: null,
    uuid: uuid.v1(),
    favorites: [],
    installation:null
  },
  mutations: {
    setGender (state, gender){
      state.gender = gender
    },
    setInstallation(state,installation){
      state.installation = installation
    },
    setActiveFamily (state, family){
      state.activeFamily=family
    },
    addCartridge (state, cartridge){
      state.favorites.push(cartridge)
    },
    // add (state, { list, data }) {
    //   state[list].push(data)
    // },
    // remove (state, { list, data }) {
    //   state[list].splice(state[list].indexOf(data), 1)
    // },
    reset (state) {
      state.uuid = uuid.v1()
      state.gender = null
      state.activeFamily = null
      state.events = []
    }
  },
  actions: {
    setInstallation(context,installation){
      context.commit('setInstallation',installation)
    },
    rateCartridge (context, {cartridge,family,value}) {
      let fakeCartridge = {
        ...cartridge,
        family: family.family,
        subFamilies: cartridge.family
      }
      if(value) context.commit('addCartridge',fakeCartridge)
    },
    setGender (context,gender) {
      context.commit('setGender',gender)
    },
    setActiveFamily (context,family) {
      context.commit('setActiveFamily',family)
    },
  },
  getters: {
    countryCode(state){
      return state.installation?.countryCode
    },
    hostId(state){
      return state.installation?.hostId
    },
    profileFamiliesWithSubs(state) {
      // let lang = getters.lang
      // let pairs = state.session.favorites.map(e =>getters['perfumes/getById'](lang,e) ).filter(e => e).map(p => { return { secondaryFamily: p.secondaryFamily, family: p.family, value: 1 } });
      let pairs = state.favorites.map(p => {
        return  { secondaryFamily: p.secondaryFamily, family: p.family, value: 1 }
      })
      let fams = Object.values(pairs.reduce((dict, { family, secondaryFamily }) => {
        dict[family.id] = dict[family.id] || { family: family, subFamilies: [] }
        dict[family.id].subFamilies.push(secondaryFamily)
        return dict
      }, {}))
      fams.forEach((f) => {
        f.subFamilies = Object.values(f.subFamilies.reduce((dict, subFamily) => {
          dict[subFamily.id] = dict[subFamily.id] || { subFamily: subFamily, count: 0 }
          dict[subFamily.id].count += 1
          return dict
        }, {}))
        f.id = f.family.id
      })
      return fams
    },
  }
}
