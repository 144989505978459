<template>
  <div :class="['app',deviceClass]">
    <div class="app-header">
      <div class="title">NOTINO</div>
    </div>
    <router-view/>
  </div>
</template>
<script>
import device from '@/utils/device';
export default {
  computed:{
    deviceClass(){
      return device
    }
  } 
}
</script>
<style lang="stylus">
body
  margin: 0

a
  color: inherit
  text-decoration: none

a:hover
  color: inherit
  cursor: pointer
  text-decoration: none
</style>
<style lang="stylus" scoped>
.app
  color: $base-font-color
  display: flex
  flex-direction: column
  font-family: $base-font-family
  height: 100%
  position: absolute
  text-align: center
  width: 100%
  -moz-osx-font-smoothing: grayscale
  -webkit-font-smoothing: antialiased
  .app-header
    background: #000
    color: #fff
    font-size: 24px
    font-weight: bold
    padding: 10px
</style>
