import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AsyncComputed from 'vue-async-computed'
import DeviceDetect from './vue-plugins/DeviceDetect'
import i18n from './i18n'

Vue.use(AsyncComputed)
Vue.mixin(DeviceDetect)
Vue.config.productionTip = false
Vue.mixin(i18n)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
