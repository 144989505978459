// function capitalize_Words(str){
//  return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
// }
export default {
  methods:{
    //Desactivamos namesizeclasses
    nameSizeClass(){
      return {} 
      // if(!name) return {}
      // return {'small-font': name.length>12, 'extra-small-font': name.length>16}
    },
    perfumeCapitalize(name){
      // return name.toLowerCase().replace(/^\w/, c => c.toUpperCase());
      // return capitalize_Words(name.toLowerCase())
      return name?name.toUpperCase():''
    }
  }
}