function log(...args) {
  console.debug("%c [Cache Api]", "color:#82CAFA", ...args);
}
export default class CacheApi {
  data = {};
  set(key, data) {
    this.data[key] = data;
  }
  get(key) {
    return this.data[key];
  }
  remove(key) {
    delete this.data[key];
  }
  cleanCache() {
    this.data = {};
  }
  cleanCachePath(path) {
    if (this.data[path]) delete this.data[path];
  }
  /**
   *
   * @param {string} name
   * @param {()=>Promise<T>} callback
   * @template T
   * @returns {Promise<T>}
   */
  wrap(name, callback) {
    if (this.get(name) !== undefined) return this.get(name);
    log("cache request", name);
    var promise = callback();
    if (promise instanceof Promise)
      promise.catch((err) => {
        this.remove(name);
        throw err;
      });
    this.set(name, promise);
    return promise;
  }
}
