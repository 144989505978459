<template>
  <div class="similar-perfumes">
    <LoadingOverlay v-if="loading" text="loading_perfumes" />
    <template v-if="results">
      <div class="results-header">
        <div class="title" v-html="t('promo_code_1')"></div>
        <div class="subtitle">{{ t("promo_code_2") }}</div>
      </div>
      <div class="similars-list" v-if="filteredResults">
        <div class="scroll-wrapper">
          <PerfumeCard
            v-for="perfume in filteredResults"
            :key="perfume.id"
            :data="perfume"
            @click="$emit('select', perfume)"
          ></PerfumeCard>
        </div>
        <div class="no-results" v-if="filteredResults.length === 0 && !loading">
          <div class="text">{{ t("no_results") }}</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
// import ListFilters from '@/components/wikiparfum/ListFilters'
import PerfumeCard from "@/components/wikiparfum/PerfumeCard";
// import {getSimilarPerfumes, getSimilarByIngredients, getSimilarByText, getAllBrands} from '@/services/api'
import LoadingOverlay from "@/components/wikiparfum/LoadingOverlay";
// import {applyFilters} from '@/utils'
// import {trackProfileRecomentadions,trackRecommendations,trackIngredientsUsedToFindPerfum} from '@/services/track'
// import { GENDER_ENUM } from '@/utils/enums'
export default {
  name: "SimilarPerfumes",
  components: {
    PerfumeCard,
    // ListFilters,
    LoadingOverlay,
  },
  props: {
    results: [Array],
    reference: [Object, Array, String],
    type: String,
    selectId: [String, Number],
  },

  data() {
    return {
      filterSelection: null,
      loading: false,
    };
  },
  computed: {
    filteredResults() {
      // return this.results && applyFilters(this.results,this.filterSelection) || []
      // return applyFilters(this.results,this.filterSelection) || []
      return this.results;
    },
  },
  asyncComputed: {
    filterDefs() {
      // return getAllBrands().then((brands)=>{
      //   return [
      //     {
      //       id: 'gender',
      //       filterLabel: 'filter_by_gender',
      //       type: 'options',
      //       fixedOptions:[{value:GENDER_ENUM.FEMALE,customLabel:'for_her'},{value:GENDER_ENUM.MALE,customLabel:'for_him'},{value:GENDER_ENUM.UNISEX,customLabel:'unisex'}],
      //       keyPath: 'gender',
      //       validation:(value,object) => value.indexOf(object)>=0
      //     },
      //     {
      //       id: 'family',
      //       filterLabel: 'filter_by_family',
      //       type: 'options',
      //       keyPath: 'family.id',
      //       labelPath: 'family.name'
      //     },
      //     {
      //       id: 'brand',
      //       filterLabel: 'filter_by_brand',
      //       type: 'options',
      //       fixedOptions: brands.map((b)=>({value:b.id,label:b.name})),
      //       keyPath: 'brand.id',
      //       female:true,
      //     }
      //   ]
      // })
    },
  },
  methods: {
    getById(id) {
      if (this.filteredResults)
        return this.filteredResults.find((p) => p.id == id);
      else return null;
    },
  },
  watch: {
    filterSelection() {
      console.log(this.filterSelection);
    },
  },
};
</script>

<style scoped lang="stylus">
.similar-perfumes
  // min-height: 100% //WORNG!! no se porque, pero hacia aparecer un scroll de mas.... :(
  position: relative

  .desktop &
    margin-left: auto
    margin-right: auto
    max-width: 150vh

  .results-header
    align-items: center
    border-bottom: 1px solid #000
    display: flex
    flex-direction: column
    margin: vw(20px) vw(20px) 0

    .desktop &
      margin: vw(10px) vw(10px) 0

    .title
      m-font-size(16)
      m-letter-spacing(20)
      font-weight: 400
      margin-bottom: vw(20px)
      text-transform: uppercase

      .mobile &
        max-width: 90%

      .desktop &
        m-font-size(12)
        margin-bottom: vw(10px)

    .subtitle
      m-font-size(13)
      m-letter-spacing(20)
      margin-bottom: vw(20px)

      .mobile &
        max-width: 60%

      .desktop &
        m-font-size(10)
        margin-bottom: vw(10px)

    .close
      m-icon("close", 20)
      margin-left: auto

  .list-filters
    flex-wrap: wrap
    height: vh(100px)
    margin: 0 vw(20px)
    padding: vw(10px) 0

    .mobile &
      border-bottom: 1px solid rgba(#000, 0.15)
      padding: vw(10px) 0
      width: 100%

      >>> .filter
        flex: 0 0 50%
        overflow: hidden
        padding: 0 vw(10px)

        &:first-child
          padding-right: vw(20px)

        .dropdown-button
          width: 100%

  .similars-list
    flex: 1 1 0%
    overflow: hidden
    padding: vw(20px)

    .desktop &
      padding: vw(10px)

    .scroll-wrapper
      overflow-y: auto

  .similars-list
    .mobile &
      margin-bottom: vh(10px)

    .scroll-wrapper
      display: flex
      flex-wrap: wrap
      height: 100%
      margin: vw(-5px)

      .mobile &
        margin: vw(-5px)

      .perfume-card
        $margin-x = vw(5px)
        // si tiene que ser "menos de 4 por fila" hay que sobreescrivir en la vista correspondiente (como en PerfumeAndSimilars que hay un 3)
        $percentage = percentage((1 / 3))
        $width = "calc(%s - (%s * 2))" % ($percentage $margin-x)
        flex: 0 0 $width
        height: 50vh // #212 keep perfume box
        // @media screen and (min-device-width : 768px) and (max-device-width : 1024px)
        // +m-breakpoint(md xmd)
        // height: vw(320px)
        margin: $margin-x auto
        overflow: hidden

        .mobile &
          $margin-x = vw(5px)
          $percentage = percentage((1 / 2))
          $width = "calc(%s - (%s * 2))" % ($percentage $margin-x)
          flex: 0 0 $width !important
          height: 35vh
          margin: $margin-x !important

  .loading-overlay
    .explore-by-concepts &
      position: fixed

      >>> .content
        justify-content: center
        margin-top: 0

    >>> .content
      margin-top: 30vh

  .no-results
    display: flex

    .text
      margin: vw(10px) auto
</style>