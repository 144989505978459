import axios from 'axios'
import store from '../../store'
import router from '../../router'

const getHostId = () =>
  store.getters.hostId ||
  localStorage.getItem("ap_host_id") ||
  process.env.VUE_APP_FALLBACK_HOST;


export function getPerfumes (ids) {
  return getPerfumeByIds(ids)
}
function getPerfumeByIds (ids) {
  if (ids.length === 0) return Promise.resolve([])
  let lang = localStorage.getItem('ap_lang') || 'pl'
  return axios
    .get(
      process.env.VUE_APP_API_URL +
        `/perfumes/by_ids?sortByEntryOrder=true&allPresent=false&perfumeIds=${ids.join(
          ','
        )}&hostId=${getHostId()}&lang=${lang}`
    )
    .then(res => res.data)
}

export function getPerfume (id) {
  let lang = localStorage.getItem('ap_lang') || 'pl'
  let hostId = getHostId()
  let cached = store.getters.getById(id)
  if (cached)
    return new Promise(function (resolve) {
      resolve(cached)
    })
  return axios
    .get(
      process.env.VUE_APP_API_URL +
        `/perfumes/${id}?hostId=${hostId}&lang=${lang}`
    )
    .then(response => {
      store.dispatch('saveById', { id: id, data: response.data })
      return response.data
      // return new Promise(function(resolve) {
      //   setTimeout(()=>{
      //     resolve(response.data)
      //   },1000)
      // });
    })
}
export function track (event) {
  let { data, ...partialEvent } = event
  let eventTransmitted = {
    ...partialEvent,
    data: data,
    app: 'notino_qr',
    uuid: router.currentRoute.query.uuid
  }

  return axios
    .post(process.env.VUE_APP_API_URL + '/usage/saveAction', eventTransmitted)
    .then(response => {
      console.log(response)
    })
}
