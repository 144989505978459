export default {
favorites_link: "OBĽÚBENÉ",
your_recomendations: "Vaše odporúčania",
loading_perfumes: "Načítanie parfumu",
filter_by_gender: "Pohlavie",
for_him: "Pre neho",
for_her: "Pre ňu",
filter_by_family: "Rodina",
filter_by_brand: "Označiť",
all: "Všetko",
all_femenine: "Všetko",
you_may_also_like: "Mohlo by sa vám páčiť",
ingredients: "prísady",
perfumist: "parfumér",
description: "popis",
olfactive_family: "rodina vôní",
expert_reviewed: "kontrolované odborníkmi",
main_family: "rodina",
sub_family: "podrodina",
me_family: "Vône sveta",
promo_code_1: "Získajte 5 % zľavu s exkluzívnym promo kódom <b> AIRPARFUM5 </b>.",
promo_code_2: "Akcia platí mesiac od dátumu vašej návštevy.",
tag_new: "Nové",
from: "od"
}