import Vue from 'vue'
import Vuex from 'vuex'
import Session from './modules/session'
import i18n from './modules/i18n'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cachedPerfumes: {}
  },
  
  modules: {
    // staticData: StaticData,
    session: Session,
    i18n: i18n 
  },
  mutations:{
    saveById(state, data) {
      state.cachedPerfumes[data.id] = data.data

    },
  },
  actions:{
    saveById(context, data) {
      context.commit('saveById', data)
    },
  },
  getters: {
    getById: state => (id) =>{
      return state.cachedPerfumes[id]
    }
    // activeFamilyCartridges: state => () => {
    //   let currentMixes = state.staticData.mixData.find((m)=>m.ean === state.session.activeFamily.ean)
    //   let result = state.staticData.cartridges.reduce((res,c)=>{
    //     let mixData = currentMixes.mixes.find((cc)=>cc.eans[1] === c.ean)
    //     if(mixData) res.push({
    //       ...c,
    //       mixData: mixData
    //     })
    //     return res
    //   },[])
    //   return result
    // }
  }
})
